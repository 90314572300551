export type Updater<X> = (old: X) => X
export type Setter<X> = (x: X | Updater<X>)=>void

export function callUpdater<T>(old: T, newVal: T | Updater<T>): T {
  if(typeof newVal === "function") {
    let newValUpd = newVal as Updater<T>
    return newValUpd(old)
  } else {
    return newVal
  }
}

export function mapSetter<T extends object, K extends keyof T>(key: K, setter: Setter<T>): Setter<T[K]> {
  return (newValue)=>{
    setter((oldValue)=>{
      let existing = oldValue[key]
      let newOne = {...oldValue}
      newOne[key] = callUpdater(existing, newValue)
      return newOne
    })
  }
}

export function mapValSetter<T extends object, K extends keyof T>(key: K, val: T, setter: Setter<T>): [T[K], Setter<T[K]>] {
  let mappedSetter = mapSetter(key, setter)
  return [val[key], mappedSetter]
}