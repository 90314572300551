import {useQuery} from "@tanstack/react-query";
import {QueueStats} from "../model/QueueStats";
import {DataResult} from "../model/DataResult";

export function useGetQueueData() {
  return useQuery({
    queryKey: ["queue"],
    queryFn: async ()=>{
      let r = await fetch('api/queue')
      let dr = (await r.json()) as DataResult<QueueStats>
      return dr.result
    },
    refetchInterval: 2000
  })
}