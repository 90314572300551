import {BaseFieldProps} from "./BaseField";
import {RadioInput} from "../Inputs/RadioInput";


interface RadioFieldProps<X, Y extends string> extends BaseFieldProps<X, Y> {
  values: Y[],
  default?: Y
}
export function RadioField<X,Y extends string>({value: queue, setValue: setQueue, field, label = field, values, default: defaultVal = values[0], disabled=false}: RadioFieldProps<X, Y>) {
  let original = queue[field]
  let value = values.find((y: Y)=>y === original) ?? defaultVal
  let set = (v: string)=>{
    let vr = values.find(y=>y === v) ?? value
    setQueue((old)=>({...old, [field]: vr}))
  }
  return <RadioInput label={label} value={value} setValue={set} values={values} disabled={disabled} />
}