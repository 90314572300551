import React from 'react';
import './App.scss';
import {BaseAppView} from "./module/AppView/BaseAppView";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {RunDataProvider} from "./util/RunDataProvider";

const client = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={client}>
      <RunDataProvider>
        <BaseAppView />
      </RunDataProvider>
    </QueryClientProvider>
  );
}

export default App;
