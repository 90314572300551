import {AgentConfig, AgentScalingTypes, PressureTypes} from "../../../model/AgentConfig";
import {Setter} from "../../../util/Setter";
import {NumberField} from "../../../components/Fields/NumberField";
import {RadioField} from "../../../components/Fields/RadioField";

interface Props {
  agentConfig: AgentConfig,
  setAgentConfig: Setter<AgentConfig>
  disabled: boolean,
  running: boolean
}
export function AgentScalingConfigForm({agentConfig, setAgentConfig, disabled, running}: Props) {
  let commonProps = {value: agentConfig, setValue: setAgentConfig, disabled}
  return <>
    <NumberField {...commonProps} field="minInstances" />
    <NumberField {...commonProps} field="maxInstances" />
    <RadioField {...commonProps} values={PressureTypes} field={"pressureType"} />
    <RadioField {...commonProps} disabled={disabled || running} values={AgentScalingTypes} field={"scalingType"} />
  </>
}