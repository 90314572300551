import Styles from "./QueueChartView.module.scss"
import {QueueSnapshot} from "../../model/QueueStats";
import {Area, AreaChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

export function formatTime(time: number): string {
  let now = Date.now()
  return Math.floor((time - now) / 1000).toString()
}

interface Props {
  snapshots: QueueSnapshot[]
}
export function QueueChartView({snapshots}: Props) {
  let start = snapshots.length > 0 ? snapshots[0].time : Date.now()
  let end = snapshots.length > 0 ? snapshots[snapshots.length - 1].time : Date.now()
  return <div className={Styles.QueueChart}>
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <AreaChart data={snapshots}>
        <XAxis dataKey="time" domain={[start, end]} tickFormatter={formatTime} type="number"/>
        <YAxis />
        <Tooltip />
        <Legend />
        <Area type="monotone" dataKey="inQueue" stackId="1" stroke="#8884d8" fill="#8884d8" label="In Queue" isAnimationActive={false}/>
        <Area type="monotone" dataKey="inProcessing" stackId="1" stroke="#82ca9d" fill="#82ca9d" label="Processing" isAnimationActive={false}/>
        {/*<Area type="monotone" dataKey="done" stackId="1" stroke="#ffc658" fill="#ffc658" label="Done" isAnimationActive={false}/>*/}
      </AreaChart>
    </ResponsiveContainer>
  </div>
}