export interface QueueConfig {
  totalTasks: number
  maxQueueSize: number
  tasksPerSecond: number
  taskConfig: QueueTaskConfig
}

export interface QueueTaskConfig {
  taskDefinitions: QueueTaskDefinition[]
}

export interface QueueTaskDefinition {
  typeId: string,
  averageTime: number,
  averageTimeStdDev: number,
  weight: number
}

export const DefaultQueueConfig: QueueConfig = {
  totalTasks: 2000,
  maxQueueSize: 40,
  tasksPerSecond: 4,
  taskConfig: {
    taskDefinitions: [
      {typeId: "Basic Task", averageTime: 5000, averageTimeStdDev: 1000, weight: 5},
      {typeId: "Loading Task", averageTime: 60000, averageTimeStdDev: 10000, weight: 1}
    ]
  }
}