import {Value} from "./Value";

function rnd(val: number) {
  return Math.round(val * 10) / 10
}

interface Props {
  title: string
  from: number
  to: number,
  duration: number
}
export function ChangeValue({title, from, to, duration}: Props) {
  let change = rnd((to - from) / duration)
  let text: string = change.toString()
  if(change > 0) text = "+" + change.toString()
  return <Value title={title} value={text} />
}