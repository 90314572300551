import {AgentStats} from "../../model/ActiveAgentsStats";
import Styles from "./SingleAgentViewBlock.module.scss"

interface Props {
  agent: AgentStats
}
export function SingleAgentViewBlock({agent}: Props) {
  let style = agent.scalingDown ? Styles.ScalingDown : Styles.SingleAgentBlock
  return <div className={style}>
    <span>ID: {agent.id}</span>
    <span>RUNNING: {agent.tasksInProgress} / {agent.maxTasks}</span>
  </div>
}

export function StartingAgentBlock() {
  return <div className={Styles.SingleAgentBlock}>
    STARTING UP!
  </div>
}