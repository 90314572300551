import {QueueConfig} from "../../../model/QueueConfig";
import {Setter} from "../../../util/Setter";
import {NumberField} from "../../../components/Fields/NumberField";
import {QueueTaskConfigForm} from "./QueueTaskConfigForm";

interface QueueConfigFormProps {
  queue: QueueConfig
  setQueue: Setter<QueueConfig>
  disabled: boolean
}
export function QueueConfigForm({queue, setQueue, disabled}: QueueConfigFormProps) {
  let commonProps = {value: queue, setValue: setQueue, disabled}
  return <>
    <NumberField field={"totalTasks"} {...commonProps} />
    <NumberField field={"maxQueueSize"} {...commonProps} />
    <NumberField field={"tasksPerSecond"} {...commonProps} />
    <QueueTaskConfigForm config={queue} setConfig={setQueue} />
  </>
}