import {createContext, PropsWithChildren, useContext} from "react";
import {RunX, useGetRunData} from "../api/useGetRunData";

const RunDataContext = createContext<RunX>({running: false})

export function RunDataProvider({children}: PropsWithChildren<{}>) {
  let {data} = useGetRunData()
  let result = data ?? {running: false}
  return <RunDataContext.Provider value={result}>
    {children}
  </RunDataContext.Provider>
}

export function useRunData(): RunX {
  return useContext(RunDataContext)
}