import {callUpdater, Setter, Updater} from "./Setter";
import {useCallback} from "react";

export type Adder<T> = (newValue: T)=>void
export function useArrayAdder<T>(setter: Setter<T[]>): Adder<T> {
  return useCallback((newValue)=>{
    setter((oldArray)=>[...oldArray, newValue])
  }, [setter])
}

export type Remover = (index: number)=>void
export function useArrayRemover<T>(setter: Setter<T[]>): Remover {
  return useCallback((index)=>{
    setter((oldArray)=>{
      let newArray = [...oldArray]
      newArray.splice(index, 1)
      return newArray
    })
  }, [setter])
}

export type ArrayUpdater<T> = (newValue: T | Updater<T>, index: number)=>void
export function useArrayUpdater<T>(setter: Setter<T[]>): ArrayUpdater<T> {
  return useCallback((newValue, index)=>{
    setter((oldArray)=>{
      let newArray = [...oldArray]
      newArray[index] = callUpdater(newArray[index], newValue)
      return newArray
    })
  }, [setter])
}

export function useArrayReducers<T>(setter: Setter<T[]>): [ArrayUpdater<T>, Adder<T>, Remover] {
  return [useArrayUpdater(setter), useArrayAdder(setter), useArrayRemover(setter)]
}