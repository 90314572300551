import Block from "../../components/Block/Block";
import {useRunData} from "../../util/RunDataProvider";
import {AgentConfig} from "../../model/AgentConfig";
import {LoadingSpinner} from "@matillion/component-library";
import {ErrorBlock} from "../../components/Block/ErrorBlock";
import Styles from "../QueueView/QueueViewBlock.module.scss";
import {useGetAgentData} from "../../api/useGetAgentData";
import {SingleAgentViewBlock, StartingAgentBlock} from "./SingleAgentViewBlock";
import {PressureChartView} from "./PressureChartView";
import AgentStyles from "./AgentViewBlock.module.scss"

function RunningAgentViewBlock({config}: {config: AgentConfig}) {
  let {data, isLoading, isFetching, isError, error} = useGetAgentData()
  if(isLoading) return <LoadingSpinner />
  if(isError) return <ErrorBlock error={error} />
  if(!data) return <ErrorBlock error={null} />
  let tarr = Array.from('x'.repeat(data.scalingUp)).map((_,i)=><StartingAgentBlock key={"S_"+i} />)
  return <div>
    <div className={Styles.FetchingBlock}>{isFetching ? <LoadingSpinner /> : null}</div>
    <div>
      <span>Type: {config.scalingType}</span>
      <span>Agents: {data.agents.length} / {data.config.maxInstances}</span>
    </div>
    <div>
      <PressureChartView pressure={data.pressure} />
    </div>
    <div className={AgentStyles.AgentSection}>
      {data.agents.map(agent=><SingleAgentViewBlock agent={agent} key={agent.id} />)}
      {tarr}
    </div>
  </div>
}

export function AgentViewBlock() {
  let running = useRunData()
  return <Block title={"Agent Status"}>
    {running.running ? <RunningAgentViewBlock config={running.agent.config} /> : "Queue not running!" }
  </Block>
}