import {Setter} from "./Setter";
import {useEffect, useState} from "react";

export function useRunConfig<T>(running: Boolean, defaultVal: T, runConfig: T | undefined): [T, Setter<T>] {
  let [isRunning, setIsRunning] = useState(false)
  let [newRunConfig, setNewRunConfig] = useState(defaultVal)
  useEffect(()=>{
    if(!isRunning && running && runConfig) {
      setNewRunConfig(runConfig)
      setIsRunning(true)
    }
    if(isRunning && !running) {
      setIsRunning(false)
    }
  }, [isRunning, runConfig, running])
  return [newRunConfig, setNewRunConfig]
}