import {Field, Label, Radio} from "@matillion/component-library";
import Styles from "./RadioInput.module.scss";

interface RProps<T extends string> {
  v: T
  value: T
  set: (y: T)=>void,
  disabled: boolean
}
function R<Y extends string>({v, value, set, disabled}: RProps<Y>) {
  let setMe = ()=>set(v)
  return <Field
    value={v}
    onChange={setMe}
    checked={value === v}
  >
    <Radio text={v} checked={v === value} onChange={setMe} disabled={disabled} />
  </Field>
}

interface Props<T extends string> {
  label: string
  value: T,
  setValue: (value: T)=>void
  values: T[]
  disabled?: boolean
}
export function RadioInput<T extends string>({label, value, setValue, values, disabled = false}: Props<T>) {
  return <Label title={label}>
    <div className={Styles.RadioDiv}>
      {values.map((v)=><R key={v} v={v} value={value} set={setValue} disabled={disabled}/>)}
    </div>
  </Label>
}