import {QueueTaskDefinition} from "../../../model/QueueConfig";
import {Setter} from "../../../util/Setter";
import {NumberField} from "../../../components/Fields/NumberField";
import {StringField} from "../../../components/Fields/StringField";

interface Props {
  definition: QueueTaskDefinition
  setDefinition: Setter<QueueTaskDefinition>
}
export function QueueTaskDefinitionForm({definition, setDefinition}: Props) {
  return <div>
    <StringField value={definition} setValue={setDefinition} field={"typeId"} />
    <NumberField value={definition} setValue={setDefinition} field={"averageTime"} />
    <NumberField value={definition} setValue={setDefinition} field={"averageTimeStdDev"} />
    <NumberField value={definition} setValue={setDefinition} field={"weight"} />
  </div>
}