import {BaseFieldProps} from "./BaseField";
import {ChangeEvent} from "react";
import {Field, Input} from "@matillion/component-library";

interface StringFieldProps<X> extends BaseFieldProps<X, string> {}
export function StringField<X>({value: queue, setValue: setQueue, disabled, field, label = field}: StringFieldProps<X>) {
  let value = queue[field]
  let set = (e: ChangeEvent<HTMLInputElement>)=>{
    let val = e.target.value
    setQueue((old)=>({...old, [field]: val}))
  }
  return <Field title={label} value={value} onChange={set} disabled={disabled}>
    <Input value={value} onChange={set} disabled={disabled}/>
  </Field>
}