export type AgentScalingType = 'ECS' | 'CHEAT' | 'ECS_AUTOSCALE' | 'EKS'
export const AgentScalingTypes: AgentScalingType[] = ['ECS', 'CHEAT', 'ECS_AUTOSCALE', 'EKS']

export type PressureType = 'UTILISATION' | 'QUEUE'
export const PressureTypes: PressureType[] = ['UTILISATION', 'QUEUE']

export interface AgentConfig {
  minInstances: number
  maxInstances: number
  scalingType: AgentScalingType
  pressureType: PressureType
}

export const DefaultAgentConfig: AgentConfig = {
  minInstances: 1,
  maxInstances: 4,
  scalingType: 'CHEAT',
  pressureType: 'QUEUE'
}