import Block from "../../../components/Block/Block";
import {AgentScalingConfigForm} from "./AgentScalingConfigForm";
import {AgentConfig} from "../../../model/AgentConfig";
import {Setter} from "../../../util/Setter";
import {useUpdateAgentConfigRun} from "../../../api/useUpdateAgentConfig";
import {UpdateBlock} from "../UpdateBlock";

interface Props {
  agentConfig: AgentConfig
  setAgentConfig: Setter<AgentConfig>
  disabled: boolean,
  running: boolean
}
export function AgentConfigBlock({agentConfig, setAgentConfig, disabled, running}: Props) {
  let {isLoading, mutateAsync} = useUpdateAgentConfigRun()
  return <Block title="Agent Config">
    <UpdateBlock isLoading={isLoading} doCall={()=>mutateAsync(agentConfig)} visible={running} />
    <div>
      <AgentScalingConfigForm agentConfig={agentConfig} setAgentConfig={setAgentConfig} disabled={disabled} running={running} />
    </div>
  </Block>
}