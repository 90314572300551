import Block from "../../../components/Block/Block";
import {Button} from "@matillion/component-library";
import Styles from "./RunControl.module.scss"
import {AgentConfig} from "../../../model/AgentConfig";
import {QueueConfig} from "../../../model/QueueConfig";
import {useStartRun, useStopRun} from "../../../api/useStartRun";

interface Props {
  running: boolean
  agentConfig: AgentConfig
  queueConfig: QueueConfig
}
export function RunControlBlock({running, agentConfig, queueConfig}: Props) {
  let {mutateAsync: start, isLoading: isStarting} = useStartRun()
  let {mutateAsync: stop, isLoading: isStopping} = useStopRun()
  let startMe = ()=>{
    start({agent: agentConfig, queue: queueConfig})
  }
  let stopMe = ()=>{
    stop()
  }
  let isLoading = isStarting || isStopping
  return <Block title="Run Controls">
    <div className={Styles.ButtonPanel}>
      <Button onClick={startMe} disabled={running || isLoading} waiting={isStarting} >Start Run</Button>
      <Button onClick={stopMe} disabled={!running || isLoading} waiting={isStopping} alt="red" >Stop Run</Button>
    </div>
  </Block>
}