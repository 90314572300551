import {QueueTaskConfig, QueueTaskDefinition} from "../../../model/QueueConfig";
import Styles from "./QueueConfigBlock.module.scss";
import {Tooltip} from "@matillion/component-library";

const colors = ["red", "green", "blue", "yellow", "orange", "purple"]

function percent(val: number) {
  return (100 * val).toLocaleString(undefined, {maximumFractionDigits: 1}) + "%"
}

function ms(val: number) {
  return val.toLocaleString(undefined, {maximumFractionDigits: 0}) + "ms"
}

interface RowProps {
  d: QueueTaskDefinition
  tw: number
  i: number
}
function MyRow({d, tw, i}: RowProps) {
  let percentage = percent(d.weight / tw)
  return <Tooltip content={d.typeId + " - " + percentage + " - " + ms(d.averageTime)} key={i}>
    <span style={{flexGrow: d.weight, backgroundColor: colors[i % colors.length]}}/>
  </Tooltip>
}

export function TaskBreakdown({config}: { config: QueueTaskConfig }) {
  if (config.taskDefinitions.length <= 1) return null
  let totalWeight = config.taskDefinitions.map(x=>x.weight).reduce((p,c)=>p+c)
  let expectedTime = config.taskDefinitions.map(x=>x.weight * x.averageTime).reduce((p,c)=>p+c) / totalWeight
  return <div className={Styles.Holder}>
    <span>Weight Breakdown</span>
    <div className={Styles.Breakdown}>
      {config.taskDefinitions.map((x, i) => <MyRow d={x} i={i} tw={totalWeight} key={i} />)}
    </div>
    <div>
      Expected Average Task Time: {ms(expectedTime)}
    </div>
  </div>
}