import {QueueViewBlock} from "../QueueView/QueueViewBlock";
import {AgentViewBlock} from "../AgentView/AgentViewBlock";
import Styles from "./BaseAppView.module.scss"
import {ConfigPanel} from "../ConfigPanel/ConfigPanel";

export function BaseAppView() {
  return <div className={Styles.App}>
    <div className={Styles.Column}>
      <ConfigPanel />
    </div>
    <div className={Styles.Column}>
      <QueueViewBlock />
    </div>
    <div className={Styles.Column}>
      <AgentViewBlock />
    </div>
  </div>
}