import {RadioInput} from "../../components/Inputs/RadioInput";
import {useState} from "react";
import {QueueSnapshot} from "../../model/QueueStats";
import {ValuesBlock} from "../../components/Display/ValuesBlock";
import {Value} from "../../components/Display/Value";
import {ChangeValue} from "../../components/Display/ChangeValue";

function average(nums: number[]): number {
  let count = nums.length
  if(count === 0) return 1
  let av = nums.reduce((p,c)=>p + c, 0) / count
  return Math.round(av * 100) / 100
}

interface AProps {
  values: QueueSnapshot[]
}
function A({values}: AProps) {
  let first = values[0]
  let last = values[values.length - 1]
  let duration = (last.time - first.time) / 1000
  let averageQueueSize = average(values.map(v=>v.inQueue))
  let averageProcessingSize = average(values.map(v=>v.inProcessing))
  return <ValuesBlock>
    <Value title={"Queue Size"} value={averageQueueSize} />
    <Value title={"In Processing"} value={averageProcessingSize} />
    <ChangeValue title={"Queue Growth (/s)"} from={first.inQueue} to={last.inQueue} duration={duration} />
    <ChangeValue title={"Done Growth (/s)"} from={first.done} to={last.done} duration={duration} />
  </ValuesBlock>
}

export const TimeFrames = {
  "30s": 30,
  "1m": 60,
  "5m": 300,
  "10m": 600
}
type TimeFrame = keyof typeof TimeFrames
const TimeFrameList: TimeFrame[] = ["30s", "1m", "5m", "10m"]

interface Props {
  snapshots: QueueSnapshot[]
}
export function QueueAverageView({snapshots}: Props) {
  let [timeframe, setTimeframe] = useState<TimeFrame>("30s")
  let lastValue = snapshots[snapshots.length - 1].time - (TimeFrames[timeframe] * 1000)
  let values = snapshots.filter(x=>x.time >= lastValue)
  return <div>
    <RadioInput label={"Time Frame"} value={timeframe} setValue={setTimeframe} values={TimeFrameList} />
    <A values={values} />
  </div>
}