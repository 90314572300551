import {QueueStats} from "../model/QueueStats";
import {useQuery} from "@tanstack/react-query";
import {ActiveAgentsStats} from "../model/ActiveAgentsStats";

export interface RunData {
  running: true
  agent: ActiveAgentsStats
  queue: QueueStats
}

export interface NotRunning {
  running: false
}
export type RunX = NotRunning | RunData


export function useGetRunData() {
  return useQuery(["run"], async ()=>{
    let r = await fetch('api/run')
    return (await r.json()) as RunX
  })
}