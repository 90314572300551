import {Button} from "@matillion/component-library";
import Styles from "./UpdateBlock.module.scss"

interface Props {
  isLoading: boolean
  doCall: ()=>unknown
  visible: boolean
}
export function UpdateBlock({isLoading, doCall, visible}: Props) {
  if(!visible) return null
  return <div className={Styles.UpdateBlock}>
    <Button onClick={doCall} waiting={isLoading} size="sm">Update</Button>
  </div>
}