import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AgentConfig} from "../model/AgentConfig";
import {QueueConfig} from "../model/QueueConfig";

interface Config {
  agent: AgentConfig
  queue: QueueConfig
}
export function useStartRun() {
  const query = useQueryClient()
  return useMutation(["run"], async (config: Config)=>{
    let r = await fetch('api/run/start', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(config)
    })
    await r.text()
  }, {onSuccess: ()=>query.invalidateQueries({queryKey: ["run"]})})
}

export function useStopRun() {
  const query = useQueryClient()
  return useMutation(["run"], async ()=>{
    let r = await fetch('api/run/stop', {method: "POST"})
    await r.text()
  }, {onSuccess: ()=>query.invalidateQueries({queryKey: ["run"]})})
}