import {QueueNumbers} from "../../model/QueueStats";
import {ValuesBlock} from "../../components/Display/ValuesBlock";
import {Value} from "../../components/Display/Value";

interface Props {
  numbers: QueueNumbers
}
export function QueueNumbersView({numbers}: Props) {
  let {inQueue, inProcessing, done, averageQueueTime, averageProcessingTime} = numbers
  return <ValuesBlock>
    <Value title={"In Queue"} value={inQueue} />
    <Value title={"In Processing"} value={inProcessing} />
    <Value title={"Done"} value={done} />
    <Value title={"Average Queue Time"} value={averageQueueTime} />
    <Value title={"Average Processing Time"} value={averageProcessingTime} />
  </ValuesBlock>
}