import Styles from "./Display.module.scss"

interface ValueProps {
  title: string,
  value: any
}
export function Value({title, value}: ValueProps) {
  return <div className={Styles.Value}>
    <span className={Styles.ValueLabel}>{title}</span>
    <span className={Styles.ValueValue}>{value}</span>
  </div>
}