import {BaseFieldProps} from "./BaseField";
import {ChangeEvent} from "react";
import {Field, Input} from "@matillion/component-library";

interface NumberFieldProps<X> extends BaseFieldProps<X, number> {
  float?: boolean
}
export function NumberField<X>({value: queue, setValue: setQueue, disabled, field, label = field, float = false}: NumberFieldProps<X>) {
  let value = queue[field]
  let set = (e: ChangeEvent<HTMLInputElement>)=>{
    let val = e.target.value
    let number = float ? parseFloat(val) : parseInt(val)
    if(!isNaN(number))
      setQueue((old)=>({...old, [field]: number}))
  }
  return <Field title={label} value={value} onChange={set} disabled={disabled}>
    <Input type="number" value={value} onChange={set} disabled={disabled}/>
  </Field>
}