import {PropsWithChildren} from "react";
import {Typography} from "@matillion/component-library";

interface Props {
  title: string
}
export default function Block({children, title = "???"}: PropsWithChildren<Props>) {
  return <div style={{border: "1px solid black"}}>
    <Typography format="tm">{title}</Typography>
    <>
      {children}
    </>
  </div>
}