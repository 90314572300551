import {useQuery} from "@tanstack/react-query";
import {DataResult} from "../model/DataResult";
import {ActiveAgentsStats} from "../model/ActiveAgentsStats";

export function useGetAgentData() {
  return useQuery({
    queryKey: ["agents"],
    queryFn: async ()=>{
      let r = await fetch('api/agent')
      let dr = (await r.json()) as DataResult<ActiveAgentsStats | undefined>
      return dr.result
    },
    refetchInterval: 2000
  })
}