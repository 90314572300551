import Block from "../../../components/Block/Block";
import {QueueConfig} from "../../../model/QueueConfig";
import {QueueConfigForm} from "./QueueConfigForm";
import {Setter} from "../../../util/Setter";
import {useUpdateQueueConfigRun} from "../../../api/useUpdateQueueConfig";
import {UpdateBlock} from "../UpdateBlock";

interface Props {
  queueConfig: QueueConfig
  setQueueConfig: Setter<QueueConfig>
  disabled: boolean,
  running: boolean
}
export function QueueConfigBlock({queueConfig, setQueueConfig, disabled, running}: Props) {
  let {mutateAsync, isLoading} = useUpdateQueueConfigRun()
  return <Block title="Queue Config">
    <UpdateBlock isLoading={isLoading} doCall={()=>mutateAsync(queueConfig)} visible={running} />
    <div>
      <QueueConfigForm queue={queueConfig} setQueue={setQueueConfig} disabled={disabled} />
    </div>
  </Block>
}