import {DefaultAgentConfig} from "../../model/AgentConfig";
import {DefaultQueueConfig} from "../../model/QueueConfig";
import {QueueConfigBlock} from "./QueueConfig/QueueConfigBlock";
import {AgentConfigBlock} from "./AgentScalingConfig/AgentConfigBlock";
import {RunControlBlock} from "./RunControl/RunControlBlock";
import {useRunData} from "../../util/RunDataProvider";
import {useRunConfig} from "../../util/useRunConfig";

export function ConfigPanel() {
  let runInfo = useRunData()
  let [agentConfig, setAgentConfig] = useRunConfig(runInfo.running, DefaultAgentConfig, runInfo.running ? runInfo.agent.config : undefined)
  let [queueConfig, setQueueConfig] = useRunConfig(runInfo.running, DefaultQueueConfig, runInfo.running ? runInfo.queue.config : undefined)
  return <>
    <QueueConfigBlock {...{queueConfig: queueConfig, setQueueConfig}} disabled={false} running={runInfo.running} />
    <AgentConfigBlock {...{agentConfig: agentConfig, setAgentConfig}} disabled={false} running={runInfo.running} />
    <RunControlBlock running={runInfo.running} {...{agentConfig, queueConfig}}/>
  </>
}