import {PressureStat} from "../../model/ActiveAgentsStats";
import Styles from "./Chart.module.scss"
import {Area, AreaChart, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {formatTime} from "../QueueView/QueueChartView";

interface Props {
  pressure: PressureStat[]
}
export function PressureChartView({pressure}: Props) {
  if(pressure.length < 2) return null
  let start = pressure[0].time
  let end = pressure[pressure.length - 1].time
  return <div className={Styles.Chart}>
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <AreaChart data={pressure}>
        <XAxis dataKey="time" domain={[start, end]} tickFormatter={formatTime} type="number"/>
        <YAxis domain={[0,1]} />
        <Tooltip />
        <Legend />
        <Area type="monotone" dataKey="pressure" stackId="1" stroke="#8884d8" fill="#8884d8" label="In Queue" isAnimationActive={false}/>
        <ReferenceLine y={0.75} label="Scale Up" />
        <ReferenceLine y={0.25} label="Scale Down" />
      </AreaChart>
    </ResponsiveContainer>
  </div>
}