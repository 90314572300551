import {useMutation, useQueryClient} from "@tanstack/react-query";
import {QueueConfig} from "../model/QueueConfig";

export function useUpdateQueueConfigRun() {
  const query = useQueryClient()
  return useMutation(["queueConfig"], async (config: QueueConfig)=>{
    let r = await fetch('api/queue', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(config)
    })
    await r.text()
  }, {onSuccess: ()=>query.invalidateQueries({queryKey: ["queue"]})})
}