import Block from "../../components/Block/Block";
import {useGetQueueData} from "../../api/useGetQueueData";
import {LoadingSpinner} from "@matillion/component-library";
import {ErrorBlock} from "../../components/Block/ErrorBlock";
import {useRunData} from "../../util/RunDataProvider";
import {QueueNumbersView} from "./QueueNumbersView";
import Styles from "./QueueViewBlock.module.scss"
import {QueueChartView} from "./QueueChartView";
import {QueueAverageView} from "./QueueAverageView";

function RunningQueueView() {
  let {data, isLoading, isFetching, isError, error} = useGetQueueData()
  if(isLoading) return <LoadingSpinner />
  if(isError) return <ErrorBlock error={error} />
  if(!data) return <ErrorBlock error={null} />
  return <div>
    <div className={Styles.FetchingBlock}>{isFetching ? <LoadingSpinner /> : null}</div>
    <QueueNumbersView numbers={data} />
    <QueueChartView snapshots={data.snapshots} />
    {data.snapshots.length > 0 ? <QueueAverageView snapshots={data.snapshots} /> : null}
  </div>
}

export function QueueViewBlock() {
  let {running} = useRunData()
  return <Block title="Queue Status">
    {running ? <RunningQueueView /> : <div>
      Queue not running!
    </div>}
  </Block>
}