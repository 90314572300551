import {QueueConfig, QueueTaskConfig, QueueTaskDefinition} from "../../../model/QueueConfig";
import {mapValSetter, Setter} from "../../../util/Setter";
import {Button, Typography} from "@matillion/component-library";
import {useArrayAdder, useArrayRemover, useArrayUpdater} from "../../../util/ArrayReducers";
import {useState} from "react";
import {QueueTaskDefinitionForm} from "./QueueTaskDefinitionForm";
import Styles from "./QueueConfigBlock.module.scss"
import {TaskBreakdown} from "./TaskBreakdown";

interface EditingListProps {
  definitions: QueueTaskDefinition[],
  setDefinitions: Setter<QueueTaskDefinition[]>
  editingIndex: number
  clear: ()=>void
}
function EditingListItem({definitions, setDefinitions, editingIndex, clear}: EditingListProps) {
  let value = definitions[editingIndex]
  let updater = useArrayUpdater(setDefinitions)
  if(!value) return null
  return <div>
    Editing {editingIndex} <Button onClick={clear}>X</Button>
    <QueueTaskDefinitionForm definition={value} setDefinition={(x)=>updater(x, editingIndex)} />
  </div>
}

interface ListProps {
  config: QueueTaskConfig
  setConfig: Setter<QueueTaskConfig>
}
function List({config, setConfig}: ListProps) {
  let [editing, setEditing] = useState<number | undefined>()
  let [definitions, setDefinitions] = mapValSetter("taskDefinitions", config, setConfig)
  let adder = useArrayAdder(setDefinitions)
  let remover = useArrayRemover(setDefinitions)
  if(editing !== undefined) {
    return <EditingListItem definitions={definitions} setDefinitions={setDefinitions} editingIndex={editing} clear={()=>setEditing(undefined)} />
  } else {
    return <div>
      Task Types:
      <ul>
        {definitions.map((x,i)=><li key={i}>
          {x.typeId}
          <div className={Styles.Actions}>
            <Button onClick={()=>setEditing(i)} size="sm">Edit</Button>
            <Button onClick={()=>remover(i)} size="sm">Remove</Button>
          </div>
        </li>)}
      </ul>
      <Button onClick={()=>{
        adder({typeId: "_", weight: 1, averageTime: 5000, averageTimeStdDev: 1000})
        setEditing(definitions.length)
      }}>+</Button>
    </div>
  }
}

interface Props {
  config: QueueConfig,
  setConfig: Setter<QueueConfig>
}
export function QueueTaskConfigForm({config, setConfig}: Props) {
  let [taskConfig, setTaskConfig] = mapValSetter("taskConfig", config, setConfig)
  return <div className={Styles.TaskConfigForm}>
    <Typography format="ts">Task Config</Typography>
    <List config={taskConfig} setConfig={setTaskConfig} />
    <TaskBreakdown config={config.taskConfig} />
  </div>
}