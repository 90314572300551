interface Props {
  error: unknown
}
export function ErrorBlock({error}: Props) {
  if(!error) return <div>Unknown error occurred</div>
  if(typeof(error) === "string")
    return <div>{error}</div>
  if(error instanceof Error)
    return <div>{error.message}</div>
  return <div>Unknown error occurred - {JSON.stringify(error)}</div>
}